import logo from './logo.svg';
import React, { useEffect } from "react";
import './App.css';
import Home from "./components/Home/index";
import ContactUs from "./components/ContactUs/index";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Egroscout from './components/EgroScout';

function App() {
  const { i18n } = useTranslation(); //translation hook

  useEffect(() => {
    const currentLang = i18n.language;
    if (currentLang === "ar") {
      document.body.classList.add("rtl");
      document.body.style.direction = "rtl";
    } else {
      document.body.classList.remove("rtl");
      document.body.style.direction = "ltr";
    }
  }, [i18n.language]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<ContactUs />} />
          {/* <Route path="/egroscout" element={<Egroscout />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
